@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400;700&display=swap");
body {
  font-family: "Noto Sans KR", sans-serif, -apple-system;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: inherit;
  list-style: none;
}
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* 스크롤 숨김 : overflow overlay 115 버전부터 제공 중지 */
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 10px;
}
*::-webkit-scrollbar-track {
  border: transparent 3px solid;
  background: transparent;
}
a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}
input::placeholder {
  font-size: 0.8em;
  color: #aaa;
}
input {
  border: none;
  text-indent: 10px;
}
input:focus {
  outline: none;
}

textarea {
  resize: none;
}

.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pointer {
  cursor: pointer;
}

.up-soon {
  opacity: 0;
}
.up {
  transition: 0.5s;
  animation: up 1s forwards ease-in-out;
}

@keyframes up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}
